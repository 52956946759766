import { Box, Container, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme, ThemeProvider, WithStyles,  withTheme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import MyAppBar from './components/MyAppBar';
import RootStore from './stores/RootStore';
import theme from './Theme';
import MyIndex from './views/my/MyIndex';
import Contact from './views/public/Contact';
import { CookiePolicy } from './views/public/CookiePolicy';
import EventRegistration from './views/public/EventRegistration';
import Home from './views/public/Home';
import PrivacyStatement from './views/public/PrivacyStatement';
import ResetPassword from './views/public/ResetPassword';
import SignIn from './views/public/SignIn';
import TermsOfUse from './views/public/TermsOfUse';
import { CookieConsent } from './views/public/components/CookieConsent';
import { CookiePreferencesDialog } from './views/public/components/CookiePreferencesDialog';
import { Footer } from './views/public/components/Footer';
import { LocalStorage } from './lib/LocalStorage';
import { CookiePreferences } from './models/CookiePreferences';

const useStyles = makeStyles((theme) => ({
	root: {
		//	flexGrow: 1
	},
	appBar: {
		backgroundColor: 'white',
		color: theme.palette.text.primary
	},
	title: {
		//flexGrow: 1
	},
	menuButton: {
		marginLeft: -18,
		marginRight: 10,
	}	
}));

interface IProps {
	rootStore?: RootStore
}

const App = withTheme(inject('rootStore')((props: IProps) => {
	const classes = useStyles();
	const [cookieConsentOpen, setCookieConsentOpen] = useState(false);
	const [cookiePreferencesDialogOpen, setCookiePreferencesDialogOpen] = useState(false);
	const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>(new CookiePreferences());
	
	useEffect(() => {
		if (!cookiePreferences.load()) 
			setCookieConsentOpen(true);
	}, []);

	const handleCookieAccept = () => {
		cookiePreferences.acceptAllAndSave();
		setCookieConsentOpen(false);
	}
	const showCookiePreferences = () => {
		setCookiePreferencesDialogOpen(true);
	}
	const handleCookieDialogClose = () => {
		setCookieConsentOpen(false);
		setCookiePreferencesDialogOpen(false);
	}
		
	return (
		<>
			<GoogleFontLoader fonts={
				[{
					// https://fonts.google.com/specimen/Raleway
					font: 'Raleway',
					weights: [400]
				}]
			} />
			<div className={classes.root}>
				<ThemeProvider theme={theme}>
					<MyAppBar />

					<Box style={{backgroundColor: 'black', padding:'10px', color: 'white', marginTop:'64px'}} position="fixed" zIndex={100}>
						<div className="yi-text-marquee">
							<div>
								<div className="row" style={{width: '6000px'}}>
									<Link href="https://yourinvited.io" target="_blank">
										<img src={process.env.PUBLIC_URL + '/assets/yourinvited-logo.png'} alt="You're Invited" height="35"/>
									</Link>
									<Typography variant="subtitle1" component="p">Tijdprikker wordt You're Invited, het platform voor het creëren van events, verlanglijstjes en meer...</Typography>
									<a href="https://yourinvited.io" className="yi-button" target="_blank">Probeer nu gratis</a>
									<Typography variant="subtitle1" component="p">Event organiseren</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Tijdsblokken aanpasbaar</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Gastenlijsten</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Uitnodigingen verzenden</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Aan- en afmelden voor event zonder account</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Aanmelding annuleren</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Publieke aanmeldlink</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Herinneringen versturen</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Realtime status updates</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Eigen foto</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Verlanglijstjes</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Verlanglijstjes koppelen aan events</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Automatische productinformatie</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Cadeautjes afstrepen en terugzetten</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<Typography variant="subtitle1" component="p">Must-Have cadeautjes</Typography>
									<img src={process.env.PUBLIC_URL + '/assets/yourinvited-icon.png'} alt="You're Invited" height="16"/>
									<a href="https://yourinvited.io" className="yi-button" target="_blank">Probeer nu gratis</a>
								</div>
							</div>
						</div>
					</Box>

					<Switch>
						<Route exact={true} path="/" component={Home} />
						<Route exact={true} path="/get-started" component={() => <Home getStartedDialogOpen={true} /> } />
						<Route exact={true} path="/about-us/privacy-statement" component={PrivacyStatement} />
						<Route exact={true} path="/about-us/terms-of-use" component={TermsOfUse} />
						<Route exact={true} path="/about-us/cookie-policy" component={CookiePolicy} />
						<Route exact={true} path="/about-us/contact" component={Contact} />
						<Route exact={true} path="/password-reset/:id" render={props => <ResetPassword id={props.match.params.id} />} />
						<Route exact={true} path="/signin" component={SignIn} />
						{/* <Route exact={true} path="/events/new" component={SoonAvailable} /> */}
						{/* <Route exact={true} path="/event/create" render={props => <NewEvent />} /> */}
						<Route exact={true} path="/events/:eventId/register" render={props => <EventRegistration eventId={props.match.params.eventId} />} />
						<Route exact={false} path="/my/" component={MyIndex} />
					</Switch>

					<Switch>
						<Route exact={true} path="/event/create" />
						<Route exact={true} path="/events/:eventId/register" />
						<Route exact={false} render={props => <Footer onCookieSettingsClick={showCookiePreferences}/> } />
					</Switch>
					<CookieConsent 
						open={cookieConsentOpen}
						onConfirm={handleCookieAccept}
						onCustomize={showCookiePreferences}/>
					<CookiePreferencesDialog 
						open={cookiePreferencesDialogOpen}
						cookiePreferences={cookiePreferences}
						onClose={handleCookieDialogClose}
					/>
				</ThemeProvider>
			</div>				
		</>
	);
}));

export { App }
